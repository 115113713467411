import { AiFillFacebook, AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdLocationPin, FaMap } from "react-icons/md";
 import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { link, text } from "../constants/tailwind-theme";

const WebsiteFooterComponent = () => (

    <div className="flex flex-row flex-wrap justify-between px-10 py-20 bg-primary-500">

        <div className="w-full md:w-4/12 border-0 p-4 ">
            <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>Contact Us</h1>

            <div className="flex flex-col flex-wrap items-start">
                <a className="flex flex-row items-center m-2 " href="tel:+233277722777" target={"_blank"}>
                    <BsFillTelephoneFill color='white' className="mr-2" size={18} />
                    <h3 className={twMerge(text.heading4, "text-white")}>0277722777/0266000262</h3>
                </a>
                <a className="flex flex-row items-center m-2 " href="mailto:divcon.gh@gmail.com" target={"_blank"}>
                    <AiOutlineMail color='white' className="mr-2" size={18} />
                    <h3 className={twMerge(text.heading4, "text-white")}>divcon.gh@gmail.com</h3>
                </a>
                <a className="flex flex-row items-center m-2 " >
                    <MdLocationPin color='white' className="mr-2" size={18} />
                    <h3 className={twMerge(text.heading4, "text-white")}>Hse. No 3 Third Labone Link, Labone,Accra ,Ghana</h3>
                </a>
                
            </div>
        </div>

        <div className="w-full md:w-4/12 border-0 p-4 ">
            <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>ABOUT US</h1>
            <h1 className={twMerge(`${text.heading4} text-justify text-white mb-4`)}>Divcon Limited is an Innovative company committed to transforming businesses and impacting the lives of our clients, customers and stakeholders. We harness resources to provide business support and supply of materials for your brand and corporate image.</h1>

        </div>

        <div className="w-full md:w-4/12 border-0 p-4">
            <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>Links</h1>
            <Link to="/home?navigateTo=home"> <h2 className={twMerge(link.heading3 ,"text-white")}>&#x2022; HOME</h2></Link>
            <Link to="/home?navigateTo=about"> <h2 className={twMerge(link.heading3 ,"text-white")}>&#x2022; ABOUT US</h2></Link>
            <Link to="/home?navigateTo=services"> <h2 className={twMerge(link.heading3 ,"text-white")}>&#x2022; OUR SERVICES</h2></Link>
            <Link to="/home?navigateTo=process"> <h2 className={twMerge(link.heading3 ,"text-white")}>&#x2022; OUR PROCESS</h2></Link>
            <Link to="/home?navigateTo=contact"> <h2 className={twMerge(link.heading3 ,"text-white")}>&#x2022; CONTACT US</h2></Link>
        </div>


    </div>
)

export default WebsiteFooterComponent;