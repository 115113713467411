import { Outlet } from "react-router-dom";


export const WebsiteLayout = () => {

    return (


            <main className="flex-1 overflow-auto" >
                <div >
                    <Outlet />
                </div>
            </main>


    )
}
export default WebsiteLayout;