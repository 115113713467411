import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const WebsiteCreditsComponent = () => (

    <div className="flex flex-row flex-wrap justify-center sm:justify-between bg-primary-500 p-4">
    <a href="https://heyphord.com" target="_blank"><p className={twMerge(text.heading3, "text-center text-white text-xs w-full underline")}>Developed By Heyphord I.T Solutions</p></a>

    <p className={twMerge(`${text.heading3} text-center text-white text-xs`)}>2023© Divcon Limited. All Rights Reserved</p>
</div>
)

export default WebsiteCreditsComponent;