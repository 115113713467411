export const flowbiteTheme =
{
  theme: {
    textInput: {
      field: {
        input: {
          color: {
            success: "bg-red-500",
          }

        }
      }

    },

    button: {
      base: "p-0.5 flex items-center justify-center text-white font-bold rounded-none ",
      color: {
        primary: "bg-primary-500",
        secondary: "bg-secondary-500",
        default: "bg-yellow-500",
        warning: "bg-cyan-500",

      }
    }

  }
}