
import React from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import HeroImageComponent from "../components/HeroImageComponent";
import ServiceItemComponent from "../components/ServiceItemComponent";
import WebsiteNavbarComponent from "../components/WebsiteNavbarComponent";
import { text } from "../constants/tailwind-theme";

import icon1 from '../assets/images/icon1.png';
import icon2 from '../assets/images/icon2.png';
import icon3 from '../assets/images/icon3.png';
import icon4 from '../assets/images/icon4.png';
import icon5 from '../assets/images/icon5.png';
import icon6 from '../assets/images/icon6.png';

import supplies1 from '../assets/images/supplies1.jpg';
import aboutus1 from '../assets/images/aboutus1.png';
import businesssupport1 from '../assets/images/businesssupport1.jpg';

import { AiOutlineTrophy } from "react-icons/ai";
import StepsItemComponent from "../components/StepsItemComponent";
import WebsiteCreditsComponent from "../components/WebsiteCreditsComponent";
import WebsiteFooterComponent from "../components/WebsiteFooterComponent";


const HomePage = (props) => {
    const [queryParams] = useSearchParams();
    const idToScrollTo = queryParams.get("navigateTo");

    React.useEffect(() => {
        console.log({ idToScrollTo });
        let scrollto = document.getElementById(idToScrollTo);
        if (scrollto) {
            scrollto.scrollIntoView({ behavior: "smooth", })
        }
    }, [idToScrollTo]);

    return (
        <div id="home" className="flex flex-col">

            < WebsiteNavbarComponent />

            <HeroImageComponent />

            {/* ABOUT US */}

            <div id="about" className="flex flex-row flex-wrap bg-tertiary-400 px-10 py-20">

                <div className="w-full lg:w-8/12 p-4 space-y-4">
                    <div className="space-y-1">
                        <h1 className={twMerge(text.heading1)}>ABOUT US</h1>
                        <p className={twMerge(`${text.description1} text-justify`)}>Divcon Limited is an Innovative company committed to transforming businesses and impacting the lives of our clients, customers and stakeholders. We harness resources to provide business support and supply of materials for your brand and corporate image.</p>
                    </div>

                    <div className="space-y-1">
                        <h1 className={twMerge(text.heading3)}>MISSION</h1>
                        <p className={twMerge(text.description1, "text-justify")}>Support businesses across Africa with product delivery and consultancy excellence.</p>
                    </div>

                    <div className="space-y-1">
                        <h1 className={twMerge(text.heading3)}>VISION</h1>
                        <p className={twMerge(`${text.description1} text-justify`)}>Be the epitome of service delivery and partnerships</p>
                    </div>

                    <div className="space-y-3">

                        <h1 className={twMerge(text.heading3)}>OUR PROMISE</h1>
                        <p className={twMerge(text.description1)}><AiOutlineTrophy size={24} color="white" className="inline p-1 bg-secondary-500 rounded-full mr-4" /> We go beyond solutions delivery to working with our clients to achieve world class results</p>
                        <p className={twMerge(text.description1)}><AiOutlineTrophy size={24} color="white" className="inline p-1 bg-secondary-500 rounded-full mr-4" /> Have a long term relationship with clients and not single visit deals</p>
                        <p className={twMerge(text.description1)}><AiOutlineTrophy size={24} color="white" className="inline p-1 bg-secondary-500 rounded-full mr-4" /> Partner with seasoned consultants to address clients specialize needs</p>
                        <p className={twMerge(text.description1)}><AiOutlineTrophy size={24} color="white" className="inline p-1 bg-secondary-500 rounded-full mr-4" /> Be the advisor and partner of choice for all clients</p>
                    </div>
                </div>

                <div className="flex w-full lg:w-4/12 items-start justify-center p-4 ">
                    <img src={aboutus1} className="rounded-lg h-full object-contain " alt="about us image" />
                </div>
            </div>

            {/* OUR SERVICES */}
            <div id="services" className="flex flex-col items-center justify-center bg-white px-10 py-20 gap-4 border">

                <h1 className={twMerge(text.heading1)}>OUR SERVICES</h1>

                {/* business support */}
                <div className="flex flex-row flex-wrap items-center w-full  space-y-4 ">

                    <div className=" flex flex-col w-full lg:w-6/12 space-y-4">
                        <h1 className={twMerge(text.heading2, "text-start")}>Business Support</h1>
                        
                        <ServiceItemComponent
                            icon={icon4}
                            heading="Research and Insights"
                            summary="*Market Research – to explore the market for opportunities, understand..."

                            bodyHTML={
                                <>*Market Research – to explore the market for
                                    opportunities, understand customers to
                                    increase profitability, competitive analysis to
                                    stay ahead, service delivery measurements
                                    (NPS, CSAT, Mystery Shopper ) to gauge
                                    consistency, brand heath check to build and
                                    maintain equity, product evaluation to ensure
                                    consumer acceptability and communication
                                    assessment to guide impact.
                                    <br />

                                    *Organizational culture research - using staff
                                    feedback to shape internal structures, behaviors
                                    and processes, and its impact on business
                                    performance.</>}
                        />
                        <ServiceItemComponent icon={icon6}
                            heading="Customer Service Advisory And Management"
                            summary="*Fully operational contact center set-up with voice and non-voice capa..."
                            bodyHTML={<>
                            *Fully operational contact center set-up with voice and non-voice capabilities. 
                            <br/>
                            *Inbound features to receive and immediately respond to customer complaints, queries and enquiries. 
                            <br/>
                            *Outbound features to conduct research and gain customer feedback, education on products and services, and for tele-marketing. 
                            <br/>
                            *Hiring, training and management of frontline personnel.
                            </>}
                        />
                        <ServiceItemComponent
                            icon={icon5}
                            heading="Experiential marketing / Direct Marketing"
                            summary="*Telemarketing *Product placement *Direct consumer engagement: One to one..."
                            bodyHTML={<>*Telemarketing *Product placement *Direct consumer engagement: One to one ,One to groups *Execution with our partners *Post activity evaluation" </>} />


                    </div>

                    <div className="flex items-center justify-center lg:justify-end w-full h-72 lg:w-6/12 px-4">
                        <img src={businesssupport1} className="rounded-lg h-full object-contain" alt="supplies image" />
                    </div>

                </div>

                <div className=" border-b my-10  w-full h-1"></div>

                {/* supplies */}
                <div className="flex flex-row lg:flex-row-reverse flex-wrap items-center w-full   space-y-4 ">

                    <div className="flex flex-col w-full lg:w-6/12 space-y-4">
                        <h1 className={`${text.heading2} text-start`}>Supplies</h1>
                        <ServiceItemComponent
                            icon={icon1}
                            heading="Corporate Items"
                            summary="*Corporate apparel *Working uniforms and protective gear  *Branded and..." 
                            bodyHTML={<>*Corporate apparel *Working uniforms and protective gear  *Branded and non branded gifts and souvenirs *Promotional items </>} />
                        <ServiceItemComponent
                            icon={icon3}
                            heading="General Accessories"
                            summary="*Broach *Badges *Wrist wear *Pendants *Rings *Ear pieces *Celebration..."
                            bodyHTML={<>*Broach *Badges *Wrist wear *Pendants *Rings *Ear pieces *Celebration and party items </>} />
                        <ServiceItemComponent
                            icon={icon2}
                            heading="Branded Merchandise"
                            summary="*Vests for sales teams and trade partners *Sun shades and umbrellas..." 
                            bodyHTML={<>*Vests for sales teams and trade partners *Sun shades and umbrellas * Cups, mugs, plates, pens, books, flasks, flash drives *Key rings </>} />
                    </div>

                    <div className="flex items-center justify-center lg:justify-start w-full h-72 lg:w-6/12 px-4 ">
                        <img src={ supplies1} className="rounded-lg h-full object-contain" alt="business support" />
                    </div>

                </div>

            </div>


            {/* HOW WE DO IT */}
            <div id="process" className="flex flex-col items-center justify-center bg-tertiary-400 px-10 py-20 space-y-4">
                {/* heading */}
                <h1 className={`${text.heading1} text-center`}>HOW WE DO IT</h1>

                <div className="flex flex-row justify-center flex-wrap mb-10">

                    <StepsItemComponent step="1" text="Alignment on services to be delivered" />
                    <StepsItemComponent step="2" text="A clearly spelt out proposal with cost and timelines" />
                    <StepsItemComponent step="3" text="End to end execution  (set-up and management plan)" />
                    <StepsItemComponent step="4" text="Weekly progress update" />
                    <StepsItemComponent step="5" text="Delivery of service" />
                    <StepsItemComponent step="6" text="Reporting" />
                    <StepsItemComponent step="7" text="Evaluation and feedback Session" />


                </div>

            </div>

            {/* OUR TEAM */}
            <div className="flex flex-col items-center justify-center bg-white px-10 py-20 space-y-4">
                {/* heading */}
                <h1 className={twMerge(text.heading1, "text-center")}>OUR TEAM</h1>

                <h1 className={twMerge(text.heading3, "text-center")}>
                    As a team, our individual experiences gained from
                    several years of work with various multinational
                    companies is our strength. This makes us a formidable
                    professional unit with the requisite expertise in helping
                    clients achieve their goals
                </h1>

            </div>

            <div id="contact" className="border-b border-black">
                <WebsiteFooterComponent />
            </div>

            <WebsiteCreditsComponent />
        </div>
    )
}

export default HomePage;