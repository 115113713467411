import './App.css';
import Theme from './pages/ThemePage'
import HomePage from './pages/HomePage'
import { RouterProvider } from 'react-router-dom';
import router from './router/routes';
import { Flowbite } from 'flowbite-react';
import { flowbiteTheme } from './constants/flowbite-theme';

function App() {
  return (

    <>
      <Flowbite theme={flowbiteTheme}>
        <RouterProvider router={router} />
      </Flowbite>
    </>
  );
}

export default App;
