import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import logo from '../assets/icons/logo.png';
import { link } from "../constants/tailwind-theme";

const WebsiteNavbarComponent = () => (

    <Navbar
        fluid={true}
        style={{backgroundColor:"#FFFFFF"}}
    >
        <Navbar.Brand href="/">
            <img
                src={logo}
                className="h-14 sm:h-16"
                alt="divcon Logo"
            />

        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
            {/* <Link to="/theme" >
                <h2 className={link.heading3}>THEME</h2>
            </Link> */}
            <Link to="/home?navigateTo=home" >
                <h2 className={link.heading3}>HOME</h2>
            </Link>

            <Link to="/home?navigateTo=about">
                <h2 className={link.heading3}>ABOUT US</h2>
            </Link>

            <Link to="/home?navigateTo=services">
                <h2 className={link.heading3}>OUR SERVICES</h2>
            </Link>

            <Link to="/home?navigateTo=process">
                <h2 className={link.heading3}>OUR PROCESS</h2>
            </Link>

            <Link to="/home?navigateTo=contact">
                <h2 className={link.heading3}>CONTACT US</h2>
            </Link>
        </Navbar.Collapse>
    </Navbar>
)

export default WebsiteNavbarComponent;