import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const StepsItemComponent = (props) =>
    <div className="flex flex-col items-center shadow-lg rounded-md bg-white p-4 m-4 w-80">
        <h1 className={twMerge( `${text.heading2} rounded-full text-center text-white w-10 h-10 bg-secondary-500 mb-1`)} >{props.step}</h1>
        <h2 className={ twMerge(`${text.heading4} text-center`)}>{props.text}</h2>

    </div>

export default StepsItemComponent;