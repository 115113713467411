import { twMerge } from "tailwind-merge"

export const text ={
    heading1: "font-heading font-bold text-4xl text-primary-500",
    heading2:"font-heading font-bold text-3xl text-primary-500",
    heading3:"font-heading font-bold text-2xl text-primary-500",
    heading4:"font-heading font-bold text-lg text-primary-500 ",
    description1:"font-body text-md text-tertiary-500 leading-5",
}

export const input ={
    default:  `rounded-md w-80 h-12 p-4  ${text.description1}`,
}

export const link ={
    heading3:  "font-heading font-bold text-lg text-primary-500 hover:text-tertiary-500 " ,
}

