import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import WebsiteLayout from "./WebsiteLayout";

import HomePage from "../pages/HomePage";
import ThemePage from "../pages/ThemePage";
import AboutPage from "../pages/AboutPage";
import ServicePage from "../pages/ServicePage";
import ContactPage from "../pages/ContactPage";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route >
            {/* Website layout */}
            <Route path="/" element={<WebsiteLayout />} >
                <Route >
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    {/* <Route path="/about" element={<AboutPage />} /> */}
                    {/* <Route path="/services" element={<ServicePage />} /> */}
                    {/* <Route path="/contact" element={<ContactPage />} /> */}
                    {/* <Route path="/theme" element={<ThemePage />} /> */}
                </Route>
            </Route>





        </Route>
    )
)

export default router;