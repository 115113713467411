import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import banner from "../assets/images/banner2.jpg"
import { text } from "../constants/tailwind-theme";
const HeroImageComponent = (props) =>
    <div className="flex flex-col items-start justify-center  w-full h-96 lg:h-[600px] bg-center bg-cover  px-10 space-y-4" style={{ backgroundImage: `url(${banner})` }}>

        <h1 className={twMerge(text.heading3, "md:text-4xl w-full md:w-10/12 text-white text-start ")} > OUR STRENGTH IS IN THE EXPERIENCE EACH MEMBER BRINGS TO THE TEAM AND OUR ABILITY TO GIVE YOU ACTIONABLE RESULTS</h1>

        <Link to={"/home?navigateTo=contact"}>
            <Button as={Link} color={"primary"}>
                CONTACT US
            </Button>
        </Link>

    </div>

export default HeroImageComponent;