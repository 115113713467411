import React from "react";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const ServiceItemComponent = ({ icon, heading, summary , bodyHTML }) => {

    const [seeMore, setSeeMore] = React.useState(false);
    return (
        <div className="flex flex-row items-center shadow-lg bg-tertiary-400 p-4 rounded-md">

            <img className="inline w-10 h-10 mr-3" src={icon} alt="icon1" />

            <div>
                <h2 className={twMerge(text.heading3, "text-start")}> {heading} </h2>
                {!seeMore && <p className={twMerge(text.description1, "mt-1 ")}>
                    {summary}
                    <span onClick={() => setSeeMore(value => !value)} className="underline cursor-pointer ml-2">{seeMore ? "see less" : "see more"}</span>
                </p>}

                {seeMore && <span className={text.description1}>
                    {bodyHTML}
                    <span onClick={() => setSeeMore(value => !value)} className="underline cursor-pointer ml-2">{seeMore ? "see less" : "see more"}</span>
                </span>}
            </div>

        </div>)
}

export default ServiceItemComponent;